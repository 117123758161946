import { gql } from "@apollo/client";

const GET_ALL_SUPPORT_CONTENT = gql`
  query GetAllSupportContent(
    $_pillarId: String
    $classificationType: Float
    $page: Float
    $perPage: Float
  ) {
    getAllSupportContent(
      _pillarId: $_pillarId
      classificationType: $classificationType
      page: $page
      perPage: $perPage
    ) {
      _id
      _contentId
      _pillarId
      _articleId
      classificationType
      contentLink
      linkImage
      color
      name
      description
      contentTypeName
      isVideo
      updatedAt
      speciality
    }
  }
`;

export default GET_ALL_SUPPORT_CONTENT;
