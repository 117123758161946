/* eslint-disable react-hooks/exhaustive-deps */
import img from "../../assets/img/pinguim.png";
import PageTitle from "../../shared/components/page-title";
import {
  MainContainer,
  TopCenterBall,
} from "../../shared/components/templates/main-container/main-container";
import {
  AssetItem,
  Assets,
  CardLeftContent,
  CardMessage,
  CardRightContent,
  CardTitle,
  CareCard,
  FirstCardMessage,
  InspitarionCard,
  LeftContent,
  MainContent,
  MonitoringContainer,
  Pinguim,
  RightCard,
  RightContent,
  SeeMoreButton,
  SuggestionCard,
} from "./style";

import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdNavigateNext } from "react-icons/md";
import ContentModal from "../../shared/components/modal-content";
import { SupportContentTypeEnum } from "../../shared/enum/support.enum";
import { ISupportContent } from "../../shared/models/support/support.model";
import SupportService from "../../shared/services/support/support.service";
import { ModalIframe } from "../activities/components/modal-task/style";
import SeeMoreModal from "./components/see-more-modal";
import "./style.css";
const Monitoring = () => {
  const data = {
    title: "Apoio",
    subtitle:
      "Esse quadro apresenta sugestões, para cuidar, aliviar, prevenir e melhorar seu bem-estar e saúde mental.",
  };
  const [inspirationSlides, setInspirationSlides] = useState<ISupportContent[]>(
    []
  );
  const isMobile: boolean = /Mobi|Android/i.test(navigator.userAgent);
  const [careIndex, setCareIndex] = useState(0);
  const [inspirationIndex, setInspirationIndex] = useState(0);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [showSeeMoreModal, setShowSeeMoreModal] = useState<boolean>(false);
  const [showSupportContentModal, setShowSupportContentModal] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [supportContentModalData, setSupportContentModalData] =
    useState<ISupportContent | null>(null);
  const [selectedCategory, setSelectedCategory] =
    useState<SupportContentTypeEnum>(SupportContentTypeEnum.Care);
  const [sugestionSlides, setSugestionSlides] = useState<ISupportContent[]>([]);
  const [careSlides, setCareSlides] = useState<ISupportContent[]>([]);
  const [currentInspirationSlide, setCurrentInspirationSlide] = useState(0);
  const { description, contentLink, linkImage } =
    inspirationSlides[currentInspirationSlide] || {};
  const prevInspirationSlide = () => {
    setCurrentInspirationSlide((prevInspirationSlide) =>
      prevInspirationSlide === 0
        ? inspirationSlides.length - 1
        : prevInspirationSlide - 1
    );
  };
  const nextInspirationSlide = () => {
    setCurrentInspirationSlide((prevInspirationSlide) =>
      prevInspirationSlide === inspirationSlides.length - 1
        ? 0
        : prevInspirationSlide + 1
    );
  };

  const prevSlideSuggestion = () => {
    const container = document.getElementById('slidesContainer');
    container && container.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const nextSlideSuggestion = () => {
    const container = document.getElementById('slidesContainer');
    container && container.scrollBy({ left: 300, behavior: 'smooth' });
  };


  const openSupportContentModal = async (item: ISupportContent) => {
    const result = await supportService.getUserSupportContent(item._id || "");
    item = {
      ...item,
      isFavorite: result?.isFavorited || false,
      isCompleted: result?.isCompleted || false,
    };
    setSupportContentModalData(item ? item : null);
    setShowSupportContentModal(true);
  };

  const closeSupportContentModal = () => {
    setShowSupportContentModal(false);
  };

  const closeShowSeeMoreModal = () => {
    setShowSeeMoreModal(false);
  };

  const openShowSeeMoreModal = (category: SupportContentTypeEnum) => {
    setShowSeeMoreModal(true);
  };
  const handleSelectCare = (selectedIndex: number) => {
    setCareIndex(selectedIndex);
  };
  const handleSelectInspiration = (selectedIndex: number) => {
    setInspirationIndex(selectedIndex);
  };
  const handleSelectSuggestion = (selectedIndex: number) => {
    setSuggestionIndex(selectedIndex);
  };

  const getYouTubeEmbedUrl = (url: string) => {
    const videoId = getYouTubeVideoId(url);
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  };

  const getYouTubeVideoId = (url: string) => {
    const videoIdMatch = url.match(/[?&]v=([^?&]+)/);
    if (videoIdMatch) {
      return videoIdMatch[1];
    }
    return null;
  };

  const renderMedia = () => {
    if (!inspirationSlides[currentInspirationSlide]) {
      return (
        <h5
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: "0",
            top: "0",
            display: "flex",
            justifyContent: "center",
            pointerEvents: "none",
            alignItems: "center",
          }}
        >
          Sem itens no momento
        </h5>
      );
    }
    if (!contentLink?.includes("youtube.com")) {
      return (
        <img
          style={{ width: "250px", maxWidth: "250px", objectFit: "cover" }}
          src={linkImage}
          alt="Imagem"
        />
      );
    } else {
      const embedUrl = getYouTubeEmbedUrl(contentLink || "");
      return (
        <ModalIframe
          width="560"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          height="315"
          src={embedUrl}
          title="Vídeo do YouTube"
          frameBorder="0"
          allowFullScreen
        />
      );
    }
  };

  const supportService = new SupportService();

  const loadData = async () => {
    setLoading(true);
    const data = await supportService.getSupportData();
    setInspirationSlides(data?.inspiration || []);
    setSugestionSlides(data?.suggestion || []);
    setCareSlides(data?.care || []);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <MainContainer>
      {
        <>
          <ContentModal
            showModal={showSupportContentModal}
            closeModal={closeSupportContentModal}
            modalData={supportContentModalData}
          />
          <SeeMoreModal
            category={selectedCategory}
            showModal={showSeeMoreModal}
            closeModal={closeShowSeeMoreModal}
          />
        </>
      }
      <TopCenterBall />
      <MonitoringContainer>
        <PageTitle prop={data} />
        <MainContent className={!isMobile ? "row" : ""}>
          <LeftContent
            className={
              !isMobile ? "w-70" : "d-flex flex-column h-100 align-items-center"
            }
          >
            <CareCard className="row animate__animated animate__fadeInDown">
              <CardLeftContent className="col-12 col-md-6 col-lg-6">
                <div className="d-flex align-items-center">
                  <CardTitle>Cuidado</CardTitle>
                  <SeeMoreButton
                    onClick={() => {
                      setSelectedCategory(SupportContentTypeEnum.Care);
                      openShowSeeMoreModal(SupportContentTypeEnum.Care);
                    }}
                    className="ms-2"
                  >
                    <AiOutlinePlusCircle
                      size={15}
                      style={{ marginRight: "5px" }}
                    />
                    Ver mais
                  </SeeMoreButton>
                </div>
                {isMobile && (
                  <>
                    {careSlides.length === 0 && (
                      <div className="text-center text-light h-100 d-flex justify-content-center align-items-center">
                        Não há itens disponíveis no momento.
                      </div>
                    )}
                    {careSlides.length > 0 && (
                      <Carousel
                        activeIndex={careIndex}
                        onSelect={handleSelectCare}
                        className="h-100 mt-3"
                      >
                        {careSlides.map((slide) => (
                          <Carousel.Item
                            interval={10000000000000000}
                            className="h-100"
                            key={slide._id}
                          >
                            <AssetItem
                              onClick={() => {
                                openSupportContentModal(slide);
                              }}
                              key={slide._id}
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundImage: `url(${slide.linkImage
                                  ? slide.linkImage
                                  : slide?.contentLink?.includes(
                                    "youtube.com"
                                  )
                                    ? `https://img.youtube.com/vi/${new URLSearchParams(
                                      new URL(slide.contentLink).search
                                    ).get("v")}/hqdefault.jpg`
                                    : "defaultImage.jpg"
                                  })`,
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span className="title px-2 mt-1 py-0">
                                  {slide.contentTypeName}
                                </span>
                                <div className="description p-3 text-center d-flex justify-content-center align-items-center">
                                  {slide.name}
                                </div>
                              </div>
                            </AssetItem>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    )}
                  </>
                )}
                {!isMobile && (
                  <>
                    {!loading ? (
                      <Assets className="row h-70 mt-2">
                        {careSlides.length === 0 ? (
                          <div className="text-center text-light">
                            Não há itens disponíveis no momento.
                          </div>
                        ) : (
                          careSlides
                            .map((slide) => (
                              <AssetItem
                                onClick={() => {
                                  openSupportContentModal(slide);
                                }}
                                key={slide._id}
                                style={{
                                  backgroundColor: "#35d590",
                                  height: "100%",
                                }}
                              >
                                <div className="d-flex flex-column h-100 justify-content-between">
                                  <span
                                    style={{
                                      background: "none",
                                      color: "white",
                                      fontWeight: "500",
                                    }}
                                    className="title px-2 mt-0 py-0"
                                  >
                                    {slide.name}
                                  </span>
                                  <span
                                    style={{
                                      background: "none",
                                      color: "black",
                                      fontWeight: "500",
                                    }}
                                    className="title px-2 mt-1 py-0"
                                  >
                                    Saiba mais
                                  </span>
                                </div>
                              </AssetItem>
                            ))
                        )}
                      </Assets>
                    ) : (
                      <Assets className="row h-70 mt-2">
                        <Skeleton
                          variant="rectangular"
                          width={155}
                          height={118}
                          className="ms-3"
                        />
                        <Skeleton
                          variant="rectangular"
                          width={155}
                          height={118}
                          className="mx-3"
                        />
                        <Skeleton
                          variant="rectangular"
                          width={155}
                          height={118}
                        />
                      </Assets>
                    )}
                  </>
                )}
              </CardLeftContent>
              <CardRightContent className="col-12 col-md-6 col-lg-6">
                {!loading && !isMobile && (
                  <FirstCardMessage>
                    De acordo com suas necessidades, iremos cuidar de você!
                  </FirstCardMessage>
                )}
                {loading && !isMobile && (
                  <Skeleton
                    className="w-100"
                    variant="text"
                    sx={{ fontSize: "2rem" }}
                  />
                )}
                {/* <BtnMeet className="ms-5">
                  <AiOutlinePlusCircle
                    size={25}
                    style={{ marginRight: "5px" }}
                  />
                  Solicitar Encontro
                </BtnMeet> */}
              </CardRightContent>
            </CareCard>
            {isMobile && (
              <>
                <InspitarionCard className="row animate__animated animate__fadeInDown animate__delay-0.5s">
                  <div className="d-flex align-items-center mb-2">
                    <CardTitle>Inspiração</CardTitle>
                    <SeeMoreButton
                      onClick={() => {
                        setSelectedCategory(SupportContentTypeEnum.Inspiration);
                        openShowSeeMoreModal(
                          SupportContentTypeEnum.Inspiration
                        );
                      }}
                      className="ms-2"
                    >
                      <AiOutlinePlusCircle
                        size={15}
                        style={{ marginRight: "5px" }}
                      />
                      Ver mais
                    </SeeMoreButton>
                  </div>
                  {isMobile && (
                    <>
                      {inspirationSlides.length === 0 && (
                        <div className="text-center text-light h-100 d-flex justify-content-center align-items-center">
                          Não há itens disponíveis no momento.
                        </div>
                      )}
                      {inspirationSlides.length > 0 && (
                        <Carousel
                          activeIndex={inspirationIndex}
                          onSelect={handleSelectInspiration}
                          className="h-100 mt-3"
                        >
                          {inspirationSlides.map((slide) => (
                            <Carousel.Item
                              interval={10000000000000000}
                              className="h-100"
                              key={slide._id}
                            >
                              <AssetItem
                                onClick={() => {
                                  openSupportContentModal(slide);
                                }}
                                key={slide._id}
                                style={{
                                  width: "100%",
                                  height: "85%",
                                  backgroundImage: `url(${slide?.linkImage
                                    ? slide?.linkImage
                                    : slide?.contentLink?.includes(
                                      "youtube.com"
                                    )
                                      ? `https://img.youtube.com/vi/${new URLSearchParams(
                                        new URL(slide.contentLink).search
                                      ).get("v")}/hqdefault.jpg`
                                      : "defaultImage.jpg"
                                    })`,
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <span className="title px-2 mt-1 py-0">
                                    {slide.contentTypeName}
                                  </span>
                                  <div className="description p-3 text-center d-flex justify-content-center align-items-center">
                                    {slide.name}
                                  </div>
                                </div>
                              </AssetItem>
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      )}
                    </>
                  )}
                </InspitarionCard>
                <SuggestionCard className="row animate__animated animate__fadeInDown animate__delay-0.5s position-relative mt-3">
                  <div className="d-flex align-items-center">
                    <CardTitle>Sugestões</CardTitle>
                    <SeeMoreButton
                      onClick={() => {
                        setSelectedCategory(SupportContentTypeEnum.Suggestions);
                        openShowSeeMoreModal(
                          SupportContentTypeEnum.Suggestions
                        );
                      }}
                      className="ms-2"
                    >
                      <AiOutlinePlusCircle
                        size={15}
                        style={{ marginRight: "5px" }}
                      />
                      Ver mais
                    </SeeMoreButton>
                  </div>
                  {isMobile && (
                    <>
                      {sugestionSlides.length === 0 && (
                        <div className="text-center text-light h-100 d-flex justify-content-center align-items-center">
                          Não há itens disponíveis no momento.
                        </div>
                      )}
                      {sugestionSlides.length > 0 && (
                        <Carousel
                          activeIndex={suggestionIndex}
                          onSelect={handleSelectSuggestion}
                          className="h-100 mt-3"
                        >
                          {sugestionSlides.map((slide) => (
                            <Carousel.Item
                              interval={10000000000000000}
                              className="h-100"
                              key={slide._id}
                            >
                              <AssetItem
                                onClick={() => {
                                  openSupportContentModal(slide);
                                }}
                                key={slide._id}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  backgroundImage: `url(${slide.linkImage
                                    ? slide.linkImage
                                    : slide?.contentLink?.includes(
                                      "youtube.com"
                                    )
                                      ? `https://img.youtube.com/vi/${new URLSearchParams(
                                        new URL(slide.contentLink).search
                                      ).get("v")}/hqdefault.jpg`
                                      : "defaultImage.jpg"
                                    })`,
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <span className="title px-2 mt-1 py-0">
                                    {slide.contentTypeName}
                                  </span>
                                  <div className="description p-3 text-center d-flex justify-content-center align-items-center">
                                    {slide.name}
                                  </div>
                                </div>
                              </AssetItem>
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      )}
                    </>
                  )}
                </SuggestionCard>
              </>
            )}
            {!isMobile && (
              <>
                <InspitarionCard className="row animate__animated animate__fadeInDown animate__delay-0.5s">
                  {inspirationSlides.length > 1 && (
                    <div className="previous" onClick={prevInspirationSlide}>
                      <MdNavigateNext></MdNavigateNext>
                    </div>
                  )}
                  <CardLeftContent
                    className="col-12 col-md-6 col-lg-6" >
                    <div className="d-flex align-items-center mb-2">
                      <CardTitle>Inspiração</CardTitle>
                      <SeeMoreButton
                        onClick={() => {
                          setSelectedCategory(
                            SupportContentTypeEnum.Inspiration
                          );
                          openShowSeeMoreModal(
                            SupportContentTypeEnum.Inspiration
                          );
                        }}
                        className="ms-2"
                      >
                        <AiOutlinePlusCircle
                          size={15}
                          style={{ marginRight: "5px" }}
                        />
                        Ver mais
                      </SeeMoreButton>
                    </div>
                    <CardMessage style={{ cursor: "pointer" }} onClick={() => { openSupportContentModal(inspirationSlides[currentInspirationSlide]) }} className="mt-3">
                      {!loading && description}
                      {loading && (
                        <div className="d-flex flex-column mt-3">
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1.5rem" }}
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1.5rem" }}
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1.5rem" }}
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1.5rem" }}
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1.5rem" }}
                          />
                          <Skeleton
                            variant="text"
                            width={300}
                            sx={{ fontSize: "1.5rem" }}
                          />
                        </div>
                      )}
                    </CardMessage>
                  </CardLeftContent>
                  <CardRightContent
                    style={{ height: "200px", overflow: "hidden" }}
                    className="col-12 col-md-6 col-lg-6"
                    role="button"
                  >
                    {!loading && renderMedia()}
                    {loading && (
                      <Skeleton
                        variant="rectangular"
                        width={510}
                        height={218}
                      />
                    )}
                  </CardRightContent>
                  {inspirationSlides.length > 1 && (
                    <div className="next" onClick={nextInspirationSlide}>
                      <MdNavigateNext></MdNavigateNext>
                    </div>
                  )}
                </InspitarionCard>
                <SuggestionCard className="row animate__animated animate__fadeInDown animate__delay-0.5s position-relative">
                  <div className="d-flex align-items-center">
                    <CardTitle>Sugestões</CardTitle>
                    <SeeMoreButton
                      onClick={() => {
                        setSelectedCategory(SupportContentTypeEnum.Suggestions);
                        openShowSeeMoreModal(
                          SupportContentTypeEnum.Suggestions
                        );
                      }}
                      className="ms-2"
                    >
                      <AiOutlinePlusCircle
                        size={15}
                        style={{ marginRight: "5px" }}
                      />
                      Ver mais
                    </SeeMoreButton>
                  </div>
                  {sugestionSlides.length > 7 && (
                    <div
                      className="previousSuggestion"
                      onClick={prevSlideSuggestion}
                    >
                      <MdNavigateNext />
                    </div>
                  )}
                  {!loading && sugestionSlides?.length > 0 && (
                    <Assets className="row" id="slidesContainer" style={{ overflowX: "auto", scrollBehavior: "smooth" }}>
                      {sugestionSlides.map((slide, index) => (
                        <AssetItem
                          onClick={() => {
                            openSupportContentModal(slide);
                          }}
                          key={slide._id}
                          style={{
                            backgroundImage: `url(${slide.linkImage
                              ? slide.linkImage
                              : slide?.contentLink?.includes("youtube.com")
                                ? `https://img.youtube.com/vi/${new URLSearchParams(
                                  new URL(slide.contentLink).search
                                ).get("v")}/hqdefault.jpg`
                                : "defaultImage.jpg"
                              })`,
                          }}
                        >
                          <div className="d-flex flex-column">
                            <span className="title px-2 mt-1 py-0">
                              {slide.contentTypeName}
                            </span>
                            <div className="description p-3 text-center d-flex justify-content-center align-items-center">
                              {slide.name}
                            </div>
                          </div>
                        </AssetItem>
                      ))}
                    </Assets>
                  )}
                  {!loading && sugestionSlides?.length === 0 && (
                    <Assets className="row">
                      <h5
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          left: "0",
                          top: "0",
                          display: "flex",
                          justifyContent: "center",
                          pointerEvents: "none",
                          alignItems: "center",
                        }}
                      >
                        Sem itens no momento
                      </h5>
                    </Assets>
                  )}
                  {loading && (
                    <Assets className="row">
                      <Skeleton
                        variant="rectangular"
                        width={300}
                        className="mb-3"
                        height={150}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={300}
                        className="mb-3 ms-3"
                        height={150}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={300}
                        className="mb-3 ms-3"
                        height={150}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={300}
                        className="mb-3 ms-3"
                        height={150}
                      />
                    </Assets>
                  )}
                  {sugestionSlides.length > 7 && (
                    <div
                      className="nextSuggestion"
                      onClick={nextSlideSuggestion}
                    >
                      <MdNavigateNext />
                    </div>
                  )}
                </SuggestionCard>
              </>
            )}
          </LeftContent>
          <RightContent
            className={`${isMobile ? "d-none" : ""
              } w-25 ms-5 animate__animated animate__fadeInRight animate__delay-0.5s`}
          >
            <RightCard className="px-5 py-4">
              <h1 className="m-0">Precisa falar com alguém?</h1>
              <button disabled className="mt-3 px-3 py-1">
                Chat - Em Breve
              </button>
            </RightCard>
            <RightCard className="mt-3 h-48 px-5 py-4">
              <h1 className="m-0">Conversa confidencial e apoio acessível</h1>
              <button disabled className="mt-3 px-3 py-1">
                Chat de IA - Em Breve
              </button>
            </RightCard>
            <Pinguim src={img}></Pinguim>
          </RightContent>
        </MainContent>
      </MonitoringContainer>
    </MainContainer>
  );
};
export default Monitoring;
