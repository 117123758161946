import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { SpeedDial } from "@mui/material";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { MdDelete } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { s3AwsService } from "../../../core/services/s3Aws.service";
import PageTitle from "../../../shared/components/page-title";
import {
  MainContainer,
  TopCenterBall,
} from "../../../shared/components/templates/main-container/main-container";
import { ArticleService } from "../../../shared/services/article/article.service";
import { MessageServices } from "../../../shared/services/message/message.service";
import { ContentsContainer } from "../../contents/style";
import { MonitoringContainer } from "../../monitoring/style";
import {
  FooterButtonsContainer,
  ImageContainer,
  StyledDropzone,
  TitleInput,
} from "./style";

export interface CreateEditArticleProps {
  articleId?: string;
  viewMode?: boolean;
}

const CreateEditArticle: React.FC<CreateEditArticleProps> = ({
  articleId,
  viewMode,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const isViewMode =
    viewMode || window.location.pathname.startsWith("/article");
  const articleService = new ArticleService();
  const messageService = new MessageServices();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchArticle = async (articleId?: string) => {
      try {
        const article = await articleService.getArticle(articleId || "");
        if (article) {
          setTitle(article.title);
          setContent(article.description);
          setImageUrl(article.image);
        }
      } catch (error) {
        messageService.error("Erro ao buscar artigo:", error as string);
      }
    };
    setLoading(false);

    if (id || articleId) {
      fetchArticle(id || articleId);
    }

    if (isViewMode) {
      setIsPreview(true);
    }
  }, [id, articleId, isViewMode]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (value: string) => {
    setContent(value);
  };

  const handleCancel = async () => {
    imageUrl && (await removeImage());
    navigate("/list-article");
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    setLoading(true);
    const file = acceptedFiles[0];
    if (file) {
      try {
        const result = await s3AwsService.uploadFile(file);
        setImageUrl(result);
      } catch (error: any) {
        messageService.error("Erro ao fazer upload:", error.message);
      }
    }
    setLoading(false);
  };

  const removeImage = async () => {
    setLoading(true);
    if (imageUrl) {
      const key = s3AwsService.extractKeyFromUrl(imageUrl);
      try {
        await s3AwsService.deleteFile(key);
        setImageUrl(undefined);
      } catch (error: any) {
        messageService.error("Erro ao remover imagem:", error.message);
      }
    }
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (articleId || id) {
        await articleService.editArticle({
          articleId: articleId || id!,
          title,
          description: content,
          image: imageUrl || "",
        });
        messageService.success("Artigo atualizado com sucesso!");
      } else {
        await articleService.insertArticle({
          title,
          description: content,
          image: imageUrl || "",
        });
        messageService.success("Artigo criado com sucesso!");
      }
      navigate("/list-article");
    } catch (error) {
      messageService.error("Erro ao salvar o artigo:", error as string);
    }
    setLoading(false);
  };

  const togglePreview = () => {
    setIsPreview(!isPreview);
  };

  const canPreview = () => {
    return title.trim() !== "" || content.trim() !== "" || imageUrl;
  };

  const canSave = () => {
    return title.trim() !== "" && content.trim() !== "";
  };

  if (isPreview) {
    return (
      <ContentsContainer>
        <MainContainer
          style={{
            minHeight: !isViewMode ? "100vh" : "100%",
            height: !isViewMode ? "100vh" : "100%",
          }}
          className="position-relative h-100"
        >
          {!viewMode && <TopCenterBall />}
          <MonitoringContainer
            className="pt-3 pe-0"
            style={{
              width: "100%",
              paddingLeft: viewMode ? "0" : "10rem",
              paddingRight: viewMode ? "0" : "5rem",
              height: "98%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!isViewMode && (
              <PageTitle
                prop={{
                  title: "Pré-visualização do Artigo",
                }}
              />
            )}
            <div style={{ zIndex: 10 }}>
              <h2>{title}</h2>
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt="Article"
                  style={{
                    width: "100%",
                    height: "300px",
                    objectFit: "contain",
                  }}
                />
              )}
              <div
                className="ql-editor my-3 p-0"
                style={{ height: "auto" }}
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            </div>
            {!isViewMode && (
              <FooterButtonsContainer className="d-flex w-100 justify-content-center p-4 pb-1 mt-auto">
                <button className="cancel-button px-4" onClick={togglePreview}>
                  Voltar à Edição
                </button>
              </FooterButtonsContainer>
            )}
          </MonitoringContainer>
        </MainContainer>
      </ContentsContainer>
    );
  }

  return (
    <ContentsContainer>
      <MainContainer className="position-relative">
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "absolute", bottom: 16, right: 16 }}
          icon={<FormatListBulletedIcon />}
          onClick={handleCancel}
          FabProps={{
            sx: {
              bgcolor: "#00df70",
              "&:hover": {
                bgcolor: "#00df70",
              },
            },
          }}
        />
        <TopCenterBall />
        <MonitoringContainer style={{ height: "98%" }}>
          <PageTitle prop={{ title: "Artigo" }} />

          <TitleInput
            type="text"
            value={title}
            placeholder="Digite o título do artigo"
            onChange={handleTitleChange}
          />

          {!imageUrl ? (
            <Dropzone onDrop={handleDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <section>
                  <StyledDropzone
                    {...getRootProps({ className: "dropzone", isDragActive })}
                  >
                    <input {...getInputProps()} />
                    <p>
                      Arraste e solte uma imagem aqui, ou clique para selecionar
                      um arquivo
                    </p>
                  </StyledDropzone>
                </section>
              )}
            </Dropzone>
          ) : (
            <ImageContainer>
              <img src={imageUrl} alt="Uploaded" />
              <button className="remove-button" onClick={removeImage}>
                <MdDelete size={24} />
              </button>
            </ImageContainer>
          )}

          <ReactQuill
            style={{ height: "50%" }}
            value={content}
            onChange={handleContentChange}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline"],
                [{ font: [] }],
                [{ size: [] }],
                [{ color: [] }, { background: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image", "video"],
                [{ align: [] }],
                ["clean"],
              ],
            }}
          />

          <FooterButtonsContainer className="mt-5 d-flex w-100 justify-content-center p-4">
            <button
              disabled={!canSave()}
              className="submit-button me-3 px-4"
              onClick={handleSave}
            >
              Salvar
            </button>
            <button
              disabled={!canPreview()}
              className="submit-button me-3 px-4"
              onClick={togglePreview}
            >
              Pré-visualizar
            </button>
            <button className="cancel-button px-4" onClick={handleCancel}>
              Cancelar
            </button>
          </FooterButtonsContainer>
        </MonitoringContainer>
      </MainContainer>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RingLoader color="#00df70" loading={true} />
        </div>
      )}
    </ContentsContainer>
  );
};

export default CreateEditArticle;
