import { requestService } from "../../../core/services/request.service";
import CREATE_SUPPORT_CONTENT from "./mutations/create-support-content.mutation";
import DELETE_SUPPORT_CONTENT from "./mutations/delete-support-content.mutation";
import EDIT_SUPPORT_CONTENT from "./mutations/edit-support-content.mutation";
import GET_ALL_CONTENTS from "./queries/get-all-contents.query";
import GET_ALL_SPECIALITIES from "./queries/get-all-specialities.query";

export interface IContent {
  _id: string;
  name: string;
}

export interface TDeleteSupportContentInput {
  supportContentId: string;
}

export interface CreateSupportContentInput {
  supportContentId?: string;
  _contentId: string;
  _pillarId: string;
  description: string;
  classificationType: number;
  linkImage?: string;
  contentLink?: string;
  name: string;
  color: string;
  _articleId?: string;
  speciality?: string | null;
}

class ContentService {
  async getAllContents(
    page?: number,
    perPage?: number
  ): Promise<IContent[] | null> {
    try {
      const result = await requestService.get(GET_ALL_CONTENTS, {
        page,
        perPage,
      });
      return result.data.getAllContents;
    } catch (error: any) {
      console.error("Error fetching all contents:", error.message);
      return null;
    }
  }

  async getAllSpecialities(): Promise<IContent[] | null> {
    try {
      const result = await requestService.getAll(GET_ALL_SPECIALITIES);
      return result.data.getAllSpecialities;
    } catch (error: any) {
      console.error("Error fetching all specialities:", error.message);
      return null;
    }
  }

  async createSupportContent(data: CreateSupportContentInput): Promise<void> {
    await requestService.mutate(CREATE_SUPPORT_CONTENT, {
      input: data,
    });
  }

  async deleteSupportContent(data: TDeleteSupportContentInput) {
    await requestService.mutate(DELETE_SUPPORT_CONTENT, {
      input: data,
    });
  }

  async editSupportContent(data: CreateSupportContentInput): Promise<void> {
    await requestService.mutate(EDIT_SUPPORT_CONTENT, {
      input: data,
    });
  }
}

export default ContentService;
